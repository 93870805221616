import React from "react";
import "./services.css";
import { FaCheck } from "react-icons/fa";

const Services = () => {
  return (
    <section id="services">
      <h5>Offer</h5>
      <h2>Service</h2>
      <div className="container services_container">
        <article className="service">
          <h3> Data Analytics</h3>
          <ul className="service__list">
            <li>
              <FaCheck className="service__list__icon" />
              <p>Datenbereinigung und EDA</p>
            </li>
            <li>
              <FaCheck className="service__list__icon" />
              <p>KPI-Identifizierung</p>
            </li>
            <li>
              <FaCheck className="service__list__icon" />
              <p>Modellwahl und -aufsatz</p>
            </li>
            <li>
              <FaCheck className="service__list__icon" />
              <p>Reporting und Visualisierung</p>
            </li>
            <li>
              <FaCheck className="service__list__icon" />
              <p>Data Journalism</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <h3>Software Development</h3>

          <ul className="service__list">
            <li>
              <FaCheck className="service__list__icon" />
              <p>Full Stack Development</p>
            </li>
            <li>
              <FaCheck className="service__list__icon" />
              <p>Web-Frontends (e.g.Dashboards)</p>
            </li>
            <li>
              <FaCheck className="service__list__icon" />
              <p>Iot</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <h3>Beratung</h3>

          <ul className="service__list">
            <li>
              <FaCheck className="service__list__icon" />
              <p>Projektbegleitung</p>
            </li>
            <li>
              <FaCheck className="service__list__icon" />
              <p>Projektplanung und -testing</p>
            </li>
            <li>
              <FaCheck className="service__list__icon" />
              <p>Prozesse und Infrastruktur</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
