import React from "react";
import "./about.css";

import ME from "../../assets/MF_Portrait.jpg";
import { LuAward } from "react-icons/lu";
import { TbUsers } from "react-icons/tb";
import { MdOutlineInterests } from "react-icons/md";

const About = () => {
  return (
    <section id="about">
      <h5>Get to know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me__image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about__content">
          <h3>
            Ab 01/2024 starte ich als Freelancer im Bereich Data Analytics und
            Data Science und bin für neue Herausforderungen offen.
          </h3>
          <p>
            Bis 12/2023 war ich als Credit Portfolio Advisor in einer
            norddeutschen Bank tätig. Im Rahmen der Steuerung des Portfolios war
            es mir wichtig, den quantitativen Aspekt im Entscheidungsprozess zu
            gewichten und dabei die aktuellen Entwicklungen im Kontext
            Portfoliooptimierung zu berücksichtigen.
          </p>
          <p>
            Mein Hintergrund als Mathematiker und langjährige Erfahrungen als
            Manager eines Quant-Teams prägen meinen Ansatz, smarte
            Entscheidungen zwischen Management Excellence und Modellierung zu
            suchen. Transparenz in der Herleitung und Methodik lassen die Daten
            ihre eigene Geschichte erzählen. Ich bin überzeugt, dass nur ein
            gutes Verständnis dieser Story zu einer wertvollen Advisory führt.
          </p>
        </div>
      </div>
      <div className="container about__container__footer">
        <div className="about__content">
          <h3>Meine Leidenschaft...</h3>
          <p>
            … ist, Modelle mit der hinreichenden Komplexität aufzusetzen und die
            Ableitungen empfängergerecht aufzuarbeiten. Mit (un-)supervised
            learning den Daten ihre Muster und Strukturen zu entlocken, aus
            Zeitreihen Forecasts abzuleiten oder im Kontext eines
            (nicht-linearen) Optimierungsproblems auf die Suche nach dem
            Pareto-Rand mit State-of-the-Art Tools (ich arbeite hauptsächlich
            mit den phantastischen R-libraries) zu gehen.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
