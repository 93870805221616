import React from "react";
import "./experience.css";
import { FaCheck } from "react-icons/fa";

const Experience = () => {
  return (
    <section id="experience">
      <h5>Skills</h5>
      <h2>Experiences</h2>
      <div className="container experience__container">
        <div className="experience__backend">
          <h3>Quant. Advisory</h3>
          <div className="experience__content">
            <article className="experience__details">
              <FaCheck className="experience__details__icon" />
              <div>
                <h4>Data Workflow</h4>
                <h5>Tidying, EDA, Monitoring, Reporting</h5>

                <small className="text-light">Experienced</small>
              </div>
            </article>

            <article className="experience__details">
              <FaCheck className="experience__details__icon" />
              <div>
                <h4>Machine Learning</h4>
                <h5>model choice</h5>
                <h5>(un-)supervised learning</h5>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheck className="experience__details__icon" />
              <div>
                <h4>Optimazation</h4>
                <h5>(nicht-) lineare Mehrzieloptimierung</h5>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheck className="experience__details__icon" />
              <div>
                <h4>Zeitreihenanalyse</h4>
                <h5>ARIMA-, Gaussian-, Baysian- approach</h5>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheck className="experience__details__icon" />
              <div>
                <h4>Stress-Testing</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__frontend">
          <h3>Development</h3>
          <div className="experience__content">
            <article className="experience__details">
              <FaCheck className="experience__details__icon" />
              <div>
                <h4>Frontend</h4>
                <h5>HTML, WebApp, Dashboard, Shiny, Jupyter, R-Markdown</h5>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheck className="experience__details__icon" />
              <div>
                <h4>Backend</h4>
                <h5>SQL, API R </h5>
                <h5>data modeling</h5>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className="experience__details">
              <FaCheck className="experience__details__icon" />
              <div>
                <h4>Sprachen/Tools</h4>
                <h5>R, C/C++,Python, ReactJS, Docker</h5>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article></article>
            <article className="experience__details">
              <FaCheck className="experience__details__icon" />
              <div>
                <h4>Data Journalism</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
