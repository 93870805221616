import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./scenes/global/Header";
import Nav from "./scenes/global/nav/Nav";
import Footer from "./scenes/global/Footer";
import Landing from "./scenes/landing";
import Policy from "./scenes/policy";
import Imprint from "./scenes/imprint";
import NoPage from "./scenes/noPage";

const App = () => {
  return (
    <div className="app">
      <main className="content">
        <BrowserRouter>
          <div id="wrapper">
            <Header />
            <Nav />
            <Routes>
              <Route exact path="/" element={<Landing />} />
              <Route exact path="/policy" element={<Policy />} />
              <Route exact path="/imprint" element={<Imprint />} />
              <Route exact path="*" element={<NoPage />} />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </main>
    </div>
  );
};

export default App;
