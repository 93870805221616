import React from "react";
import "./header.css";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h3>Dr. Michael Friedland</h3>
        <h5 className="text-light"> Data Scientist </h5>
      </div>
    </header>
  );
};

export default Header;
