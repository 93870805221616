import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="container footer__container">
        <div className="footer__col">
          <small>
            <div className="permalinks">
              <Link to="/policy">Datenschutzerklärung</Link>
              <Link to="/imprint">Impressum</Link>
            </div>
          </small>
        </div>
        <div className="footer__col"></div>
        <div className="footer__col">
          <small>
            <div className="footer__copyright">
              &copy; 2024 Michael Friedland <div>·</div>
              <Link to="https://www.friedland-design.de" target="_blank">
                friedland-design
              </Link>
              <div>·</div>
              All rights reserved.
            </div>
          </small>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
