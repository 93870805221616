import React from "react";
import About from "./components/about/About";
import Experience from "./components/experience/Experience";
import Services from "./components/services/Services";
import Contact from "./components/contact/Contact";
import Nav from "../global/nav/Nav";

import "./index.css";

const Landing = () => {
  return (
    <div className="landing">
      <About />
      <Experience />
      <Services />
      <Contact />
    </div>
  );
};

export default Landing;
