import React from "react";
import "./nav.css";
import { CiHome } from "react-icons/ci";
import { AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
import { RiServiceLine } from "react-icons/ri";
import { FiMessageSquare } from "react-icons/fi";
import { useState } from "react";

import { HashLink as Link } from "react-router-hash-link";
import { LuLink2Off } from "react-icons/lu";

const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");
  return (
    <nav>
      <Link
        to="/#"
        onClick={() => setActiveNav("#")}
        className={activeNav === "#" ? "active" : ""}
      >
        <CiHome />
      </Link>
      <Link
        to="/#about"
        onClick={() => setActiveNav("about")}
        className={activeNav === "about" ? "active" : ""}
      >
        <AiOutlineUser />
      </Link>
      <Link
        to="/#experience"
        onClick={() => setActiveNav("experience")}
        className={activeNav === "experience" ? "active" : ""}
      >
        <BiBook />
      </Link>
      <Link
        to="/#services"
        onClick={() => setActiveNav("services")}
        className={activeNav === "services" ? "active" : ""}
      >
        <RiServiceLine />
      </Link>
      <Link
        to="/#contact"
        onClick={() => setActiveNav("contact")}
        className={activeNav === "contact" ? "active" : ""}
      >
        <FiMessageSquare />
      </Link>
    </nav>
  );
};

export default Nav;
