import React from "react";
import "./index.css";

const Imprint = () => {
  return (
    <section id="imprint">
      <div className="container imprint__container">
        <h2>Angaben gemäß § 5 TMG</h2>
        <p>
          Dr. Michael Friedland
          <br />
          Im Kley 4<br />
          30989 Gehrden
          <br />
          Deutschland
        </p>
        <p>Steuernr. 23/113/00754</p>
        <h2>Kontakt</h2>
        <p>
          Telefon: +49 (0) 1708422008
          <br />
          E-Mail:{" "}
          <a mailto="info@michael-friedland.de"> info@michael-friedland.de </a>
        </p>
        <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
        <p>
          Quelle:{" "}
          <a href="https://www.e-recht24.de">https://www.e-recht24.de</a>
        </p>
      </div>
    </section>
  );
};

export default Imprint;
