import React from "react";
import "./index.css";

const NoPage = () => {
  return (
    <section id="nopage">
      <div className="container nopage__container">
        <h3> No Page found!!</h3>
      </div>
    </section>
  );

  return <div>No PAGE Found on that route!</div>;
};

export default NoPage;
